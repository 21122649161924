import React, { useState } from 'react';
import Login from './components/Login';
import { connectWebSocket } from './api/webSocketClient';

const App = () => {
  const [token, setToken] = useState(null);

  const handleLogin = (token) => {
    setToken(token);
    connectWebSocket(token); // Устанавливаем соединение с WebSocket после логина
  };

  return (
    <div>
      {!token ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div>
          <h1>Welcome to the chat!</h1>
          {/* Добавьте компоненты чата здесь */}
        </div>
      )}
    </div>
  );
};

export default App;
