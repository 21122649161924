import SockJS from 'sockjs-client';
import { CompatClient } from '@stomp/stompjs';

let stompClient = null;

export const connectWebSocket = (token) => {
  const socket = new SockJS('http://localhost:8080/ws'); // Замените на ваш URL
  stompClient = new CompatClient({
    webSocketFactory: () => socket,
    reconnectDelay: 5000,
    connectHeaders: {
      Authorization: `Bearer ${token}`,
    },
    onConnect: (frame) => {
      console.log('Connected: ' + frame);
      // Здесь вы можете вызвать функции для подписки на топики, например:
      // stompClient.subscribe('/topic/messages', onMessageReceived);
    },
    onStompError: (error) => {
      console.error('STOMP error', error);
    },
  });

  stompClient.activate();
};

export const sendMessage = (message) => {
  if (stompClient && stompClient.active) {
    stompClient.publish({
      destination: '/app/chat',
      body: JSON.stringify(message),
    });
  } else {
    console.error('STOMP client is not connected.');
  }
};
